<template>
    <Layout>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 font-size-18 fw-bold">
                {{ $t('agent transaction summary') }}
            </h4>
        </div>
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="1">
                                    {{ $t('date') }}
                                </td>
                                <td colspan="3" class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center hidden">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                    <!-- <div class="input-group"> -->
                                    <!-- <select class="form-control">
                                                    <option
                                                        value=""
                                                        selected
                                                    >
                                                        {{ $t('all') }}
                                                    </option>
                                                </select> -->
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                    <!-- <button
                                                    :class="`btn btn-success ${!loading||'disabled'}`"
                                                    @click="initList(1)"
                                                >
                                                    {{ $t('search') }}
                                                </button> -->

                                    <!-- </div> -->
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col" class="fz">
                                    <a
                                        href="javascript:void(0);"
                                        class="anchor-hover"
                                        @click="sort('username')">
                                        <i
                                            class="bx"
                                            :class="{
                                                'bx-caret-down bx-flashing':
                                                    filters.order == 'desc' &&
                                                    filters.sort == 'username',
                                                'bx-caret-up bx-flashing':
                                                    filters.order == 'asc' &&
                                                    filters.sort == 'username',
                                            }"></i>
                                        <span
                                            >{{ $t('level') }}/
                                            {{ $t('username') }}</span
                                        >
                                    </a>
                                </th>
                                <th scope="col">
                                    {{ $t('total deposit count') }}
                                </th>
                                <th scope="col">
                                    {{ $t('total deposit amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('total withdraw count') }}
                                </th>
                                <th scope="col">
                                    {{ $t('total withdraw amount') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="r in data.list"
                                :key="r._id"
                                :class="{
                                    selected: user_ids.includes(r._id),
                                }">
                                <td class="fz">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            class="text-secondary"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    'agent'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <div
                                            class="note-icon"
                                            v-if="r.user.agent.note">
                                            <i class="bx bxs-note fs-4"></i>
                                            <span class="tooltip">
                                                <p>{{ r.user.agent.note }}</p>
                                            </span>
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user._id,
                                                    'agent'
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi mdi-numeric-${r.user.agent.level}-box fs-5  level-${r.user.agent.level}`"></i>
                                            {{ r.user.username }}
                                            <i
                                                class="bx bxs-circle bx-flashing text-warning font-size-11"
                                                v-if="r.user.logged_in"></i
                                        ></a>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ numberString(r.total_deposit_count) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.total_deposit) }}
                                </td>
                                <td class="text-center">
                                    {{ numberString(r.total_withdraw_count) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.total_withdraw) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <!-- @click="scroll('right')" -->
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import AgentManagementTabs from '@/components/agent-management-tabs';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import Multiselect from '@vueform/multiselect';
import { required, minValue, maxValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Datepicker,
        Multiselect,
        AgentManagementTabs,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            show_account_details: false,
            title: `agents`,
            filters: {
                limit: 50,
                sort: null,
                order: null,
                keyword: null,
                username: null,
                status: null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
                referrer_id: null,
                upline_id: this.$route.query.upline_id || null,
            },
            referrer_ids: [
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
            agents_options: [],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            total: {
                total_deposit_player_amount: 0,
                total_withdraw_player_amount: 0,
                total_difference_amount: 0,
                total_player_bet_amount: 0,
                total_player_win_amount: 0,
                total_player_profit_amount: 0,
                total_agent_balance: 0,
            },
            submitted: false,
            asset_modal: false,
            pl_asset: {
                _id: null,
                username: null,
                name: null,
                nickname: null,
                agent: {
                    level: null,
                    assets: {
                        balance: 0,
                        points: 0,
                    },
                },
                type: 'balance',
                amount: 0,
                note: null,
            },
            loading: false,
            total_loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
            batch_update_modal: false,
            batch_update_column: '',
            user_ids: [],
            bank: {
                physical: {
                    enabled: false,
                    default: true,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
                virtual: {
                    enabled: false,
                    default: false,
                    bank_name: '',
                    account_holder: '',
                    account_number: '',
                },
            },
            childWindow: null,
        };
    },
    watch: {
        filters: {
            async handler() {
                this.initList(1);
            },
            deep: true,
        },
        referrer_ids: {
            async handler(agents) {
                var id = null;
                agents.forEach((agent, i) => {
                    if (agent != null) {
                        id = agent._id;
                    }
                });
                this.filters.referrer_id = id;
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('agent', {
            agentGetFilterList: 'getFilterList',
            agentGetList: 'getList',
            agentGetListTotal: 'getListTotal',
            agentDelete: 'delete',
            agentBatchUpdate: 'batchUpdate',
            agentUpdateAssets: 'updateAssets',
            agentLogout: 'logout',
            agentGetTransactionSummary: 'getTransactionSummary',
        }),
        handleFilter(value) {
            if (!value) return;
            var startIndex = value.option_index + 1;
            var loop = this.agents_options.length;
            if (startIndex < loop) {
                for (var i = startIndex; i < loop; i++) {
                    this.agents_options.splice(startIndex, 1);
                    this.referrer_ids[i] = null;
                }
            }
            this.initFilter(value._id);
        },
        sort(column) {
            if (this.filters.sort != column) {
                this.filters.order = 'desc';
            } else {
                if (this.filters.order == 'desc') this.filters.order = 'asc';
                else this.filters.order = 'desc';
            }
            this.filters.sort = column;
        },
        handleClear(index) {
            var loop = this.agents_options.length;
            var startIndex = index + 1;
            for (var i = startIndex; i < loop; i++) {
                this.agents_options.splice(startIndex, 1);
                this.referrer_ids[i] = null;
            }
            if (index == 0) this.referrer_ids[0] = null;
        },
        async initFilter(referrer_id) {
            const data = await this.agentGetFilterList({
                referrer: referrer_id || null,
            });
            var agents = [];
            data.forEach((agent) => {
                agent.option_index = this.agents_options.length;
                agents.push({
                    label: agent.username,
                    value: agent,
                });
            });
            // this.referrer_ids.push()
            if (agents.length) {
                this.agents_options.push(agents);
            }
        },
        async initList(p) {
            this.user_ids = [];
            var scrollLeft = this.$refs.tableScroller.scrollLeft;
            var pl = {
                sort: this.filters.sort,
                order: this.filters.order,
                limit: this.filters.limit,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                level: [
                    'level-1',
                    'level-2',
                    'level-3',
                    'level-4',
                    'level-5',
                ].includes(this.$route.params.level)
                    ? this.$route.params.level.replace('level-', '')
                    : null,
                page_no: p ? p : 1,
                username: this.filters.username,
                search: this.filters.keyword || null,
                referrer_id: this.filters.referrer_id,
                upline_id: this.filters.upline_id,
            };
            this.loading = true;
            const data = await this.agentGetTransactionSummary(pl);
            this.data.list = data;
            // this.data.links = data.links;
            // this.data.current_page = data.current_page;
            // this.data.per_page = data.per_page;
            // this.data.last_page = data.last_page;
            // this.data.total = data.total;
            this.loading = false;

            this.resetScroll(scrollLeft);
        },
        async initListTotal() {
            this.user_ids = [];
            var scrollLeft = this.$refs.tableScroller.scrollLeft;
            var pl = {
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                level: [
                    'level-1',
                    'level-2',
                    'level-3',
                    'level-4',
                    'level-5',
                ].includes(this.$route.params.level)
                    ? this.$route.params.level.replace('level-', '')
                    : null,
                username: this.filters.username,
                search: this.filters.keyword || null,
                referrer_id:
                    this.$route.params.level == 'all'
                        ? this.filters.referrer_id
                        : null,
                upline_id: this.filters.upline_id,
            };

            this.total_loading = true;
            const data = await this.agentGetListTotal(pl);
            this.total.total_deposit_player_amount =
                data.total_deposit_player_amount;
            this.total.total_withdraw_player_amount =
                data.total_withdraw_player_amount;
            this.total.total_difference_amount = data.total_difference_amount;
            this.total.total_player_bet_amount = data.total_player_bet_amount;
            this.total.total_player_win_amount = data.total_player_win_amount;
            this.total.total_player_profit_amount =
                data.total_player_profit_amount;
            this.total.total_agent_balance = data.total_agent_balance;

            this.total_loading = false;

            this.resetScroll(scrollLeft);
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
            this.filters.keyword = null;
            this.filters.username = null;
            this.filters.status = null;
            // this.initList(1);
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
    },
    mounted() {
        this.initFilter();
        this.initList(1);
        // this.initListTotal();
    },
};
</script>
